
import React, {useState, useRef, useContext} from 'react'
import {Form, Button, Card, Alert, Container} from 'react-bootstrap'
import { Link, navigate } from "gatsby"
import { FirebaseAuth,signup, loggedUser } from  "../components/FirebaseAuth"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import ThemeContext from "../utils/theme"

export default function Signup() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const { dark } = useContext(ThemeContext)


  async function callSignUp()
  {
    await signup(emailRef.current.value, passwordRef.current.value)
    .then(()=>
    {
      let profileInfo = loggedUser()
      let neededData = {"email":profileInfo.email,"displayName":profileInfo.displayName,"uid":profileInfo.uid}
      //console.log("profile is",neededData)
      localStorage.setItem('profileData', JSON.stringify(neededData) )
      navigate(`/`)
    })
    .catch((e) => 
    {
      setError(`Failed to create an account. ${e && e.message}`)
    })
    
  }

  function handleSubmit(e) 
  {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) 
    {
      return setError("Passwords do not match")
    }
    try 
    {
      setError("")
      setLoading(true)
      callSignUp()
    } 
    catch
    {
      setError("Failed to create an account ")
    }
    setLoading(false)
  }

  return (
    <>
    <PageLayout>
      <SEO title="Sign Up" />
      <Container className="d-flex align-items-center justify-content-center" >
      <div className="w-100 text-color" style={{ maxWidth: "400px" }}>
        <Card style={{ borderColor:`${dark ? '#333' : '#ddd'}`,borderWidth:"3px" }}>
          <Card.Body>

            <h2 className="text-center mb-4">Sign Up</h2>
            {error && <Alert style={{fontWeight:"900",backgroundColor:"#d9534f",color:"white",borderColor:"#d9534f"}}>{error}</Alert>}

            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <br/>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <br/>
              <Form.Group id="password-confirm">
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control type="password" ref={passwordConfirmRef} required />
              </Form.Group>
              <br/>
              <Button disabled={loading}  type="submit">
                Sign Up
              </Button>
            </Form>

          </Card.Body>
        </Card>

        <div className="w-100 text-center mt-2">
          Already have an account? <Link to="/Login">Login</Link>
        </div>
        
      <br/><br/>
      </div>
      </Container>
    </PageLayout>
    </>
  )
}